import { assemblyParameters } from "@shared/functions";
import { apiSlice } from ".";

export const adsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAds: builder.query({
      query: (params) => `ads?${assemblyParameters(params)}`,
      transformResponse: ({ data }) => data,
      providesTags: [{ type: "ads", id: "LIST" }],
    }),
    getAdDetail: builder.query({
      query: (id) => `ads/${id}`,
      transformResponse: ({ data }) => data,
      providesTags: [{ type: "ads", id: "DETAIL" }],
    }),
    createAds: builder.mutation({
      query: (data) => ({
        url: `ads`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "ads", id: "LIST" }],
    }),
    updateAds: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `ads/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: [{ type: "ads", id: "LIST" }],
    }),
    deleteAds: builder.mutation({
      query: (id) => ({
        url: `ads/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "ads", id: "LIST" }],
    }),
  }),
});

export const {
  useGetAdsQuery,
  useLazyGetAdsQuery,
  useGetAdDetailQuery,
  useLazyGetAdDetailQuery,
  useCreateAdsMutation,
  useUpdateAdsMutation,
  useDeleteAdsMutation,
} = adsApi;
