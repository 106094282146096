import React, { useState } from "react";
import { Link } from "react-router-dom";

import TableListNews from "../table/TableListNews";
import styles from "./list-news.module.css";
import { useGetNewsQuery } from "@store/service/apis";
import { removeEmptyObject } from "@shared/functions";

const ListNews = (props: any) => {
  const [search, setSearch] = useState(null);
  const [title, setTitle] = useState(null);

  const { data: news } = useGetNewsQuery(removeEmptyObject({ title }));

  const handleSearch = () => {
    if (search !== null) setTitle(search);
  };

  return (
    <div className={styles.wrapListNews}>
      {news ? (
        <>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Ketikan judul artikel yang ingin dicari!"
              onChange={(e: any) => {
                setSearch(e.target.value);
              }}
            />
            <div className="input-group-prepend">
              <span
                className="input-group-text"
                style={{ cursor: "pointer" }}
                onClick={handleSearch}
              >
                Cari Artikel
              </span>
            </div>
          </div>
          <TableListNews news={news} user={props.user} />
        </>
      ) : (
        <>
          <h1 className={styles.titleInfo}>
            Kamu belum menuliskan sesuatu nih.
          </h1>
          <p className={styles.subTitleInfo}>
            Yuk menulis, supaya halaman profile kamu lebih menarik lagi !
          </p>
          <Link to="/admin/create-article" className={styles.btnCreate}>
            Mulai Menulis
          </Link>
          <img
            src="https://www.idntimes.com/assets/img/empty/empty-profile.png"
            className={styles.imgInfo}
            alt="img"
          />
        </>
      )}
    </div>
  );
};

export default ListNews;
