import React, { FC } from "react";
import TextareaAutosize from "react-textarea-autosize";

import { IFieldProps } from "@shared/interfaces";

export const TextField: FC<IFieldProps> = ({ formik, name, placeholder }) => {
  return (
    <div>
      <TextareaAutosize
        className={"input-form tw-w-full"}
        name={name}
        onKeyUp={formik.handleChange}
        placeholder={placeholder}
        defaultValue={formik.values[name]}
      />
      {formik.errors[name] && (
        <p className="msg-error">{formik.errors[name]}</p>
      )}
    </div>
  );
};
