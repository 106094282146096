class Auth {
  constructor() {
    const userLocal = localStorage.getItem("c_user");
    if (userLocal !== null) {
      this.authenticated = true;
    } else {
      this.authenticated = false;
    }
  }

  login(cb) {
    this.authenticated = true;
    cb();
  }

  logout(cb) {
    this.authenticated = false;
    cb();
  }

  isAuthenticated() {
    return this.authenticated;
  }

  user() {
    const userLocal = localStorage.getItem("c_user");
    if (userLocal) {
      return atob(userLocal);
    }
    return null;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Auth();
