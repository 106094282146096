import React, { useEffect, useState } from "react";
import imageCompression from "browser-image-compression";
// @ts-ignore
import Select from "react-select";
// @ts-ignore
import DatePicker from "react-datepicker";
// @ts-ignore
import SweetAlert from "sweetalert2-react";
import TextareaAutosize from "react-textarea-autosize";
import { useHistory } from "react-router";
// import { format } from "date-fns";
import { format } from "date-fns";

import styles from "./create.module.css";
import "react-datepicker/dist/react-datepicker.css";

import { api } from "@shared/api";
import { numeric } from "@shared/functions";
import { POPADS } from "@shared/constants";
import { useCreateAdsMutation } from "@store/service/apis";

const FormCreateAds = () => {
  const [optionAdsType, setOptionAdsType] = useState<any>();
  const [adsType, setAdsType] = useState<any>();
  const [adsTypeId, setAdsTypeId] = useState<any>();
  const [isAdsTypeError, setIsAdsTypeError] = useState(false);
  const [title, setTitle] = useState<any>();
  const [isTitleError, setIsTitleError] = useState(false);
  const [url, setUrl] = useState<any>();
  const [price, setPrice] = useState<any>();
  const [isActive, setIsActive] = useState(0);
  const [marketing, setMarketing] = useState<any>();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [img, setImg] = useState<any>();
  const [isSuccessSave, setIsSuccessSave] = useState(false);
  const [optionAdvList, setOptionAdvList] = useState<any>();
  const [advList, setAdvList] = useState<any>();

  const history = useHistory();

  useEffect(() => {
    api
      .get(`admin/ads-type/for-form`)
      .then((res) => setOptionAdsType([...res.data.data, POPADS]));
    api.get(`admin/adv-list`).then((res) => setOptionAdvList(res.data.data));
  }, []);

  useEffect(() => {
    if (title === null) {
      setIsTitleError(false);
    } else if (title?.length === 0) {
      setIsTitleError(true);
    } else {
      setIsTitleError(false);
    }
  }, [title]);

  useEffect(() => {
    if (adsType) {
      setAdsTypeId(adsType.id ? adsType.id : 0);
    }
  }, [adsType]);

  const handleGetImg = async (e: any) => {
    const imageFile = e.target.files[0];
    const options = {
      maxSizeMB: 0.7,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      getBase64(compressedFile);
    } catch (error) {
      console.log(error);
    }
  };

  const getBase64 = (file: any) => {
    let reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setImg(reader.result);
    };
    reader.onerror = function (error: any) {
      console.log("Error: ", error);
    };
  };

  const [createAds, { isLoading }] = useCreateAdsMutation();

  const handleSave = async () => {
    setIsTitleError(false);
    if (!title) {
      setIsTitleError(true);
      return;
    }

    const data = {
      ads_type_id: adsTypeId,
      category_id: advList?.value || undefined,
      title: title,
      url: url,
      price: price,
      start_date: startDate ? format(startDate, "yyyy-MM-dd") : "",
      end_date: endDate ? format(endDate, "yyyy-MM-dd") : "",
      is_active: isActive,
      marketing: marketing,
      image: img,
    };

    try {
      createAds(data)
        .unwrap()
        .then(() => {
          setIsSuccessSave(true);
          history.goBack();
        });
    } catch (error) {
      setIsSuccessSave(false);
    }
  };

  return (
    <>
      <div className={styles.wrapForm}>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label text-right">
            Jenis Iklan
          </label>
          <div className="col-sm-9">
            <Select
              value={adsType}
              placeholder="Pilih jenis iklan"
              onChange={(e: any) => {
                setAdsType(e);
                setIsAdsTypeError(false);
              }}
              options={optionAdsType}
            />
            {isAdsTypeError && (
              <p className={styles.messageError}>
                Kategori berita jangan dikosongkan!
              </p>
            )}
          </div>
        </div>
        <div className={"mt-2"}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-right">
              Kanal Advertorial
            </label>
            <div className="col-sm-9">
              <Select
                value={advList}
                placeholder="Pilih kanal advertorial"
                onChange={(e: any) => setAdvList(e)}
                options={optionAdvList}
              />
            </div>
          </div>
        </div>
        <div className={"mt-2"}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-right">
              Title Iklan
            </label>
            <div className="col-sm-9">
              <TextareaAutosize
                className={"form-control " + styles.inputForm2}
                onKeyUp={(e: any) => {
                  const { value } = e.target;
                  setTitle(value);
                  if (value?.length > 0) {
                    setIsTitleError(false);
                  } else {
                    setIsTitleError(true);
                  }
                }}
                placeholder={"Tulis title iklan disini"}
                maxLength={255}
              />
              {isTitleError && (
                <p className={styles.messageError}>
                  Title iklan jangan dikosongkan!
                </p>
              )}
            </div>
          </div>
        </div>
        <div className={"mt-2"}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-right">
              URL Iklan
            </label>
            <div className="col-sm-9">
              <TextareaAutosize
                className={"form-control " + styles.inputForm2}
                onKeyUp={(e: any) => {
                  setUrl(e.target.value);
                }}
                placeholder={
                  "URL iklan disini, lengkap dengan http atau https. Misal: https://kukarkab.co.id"
                }
                maxLength={255}
              />
            </div>
          </div>
        </div>
        <div className={"mt-2"}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-right">
              Harga Iklan
            </label>
            <div className="col-sm-9">
              <TextareaAutosize
                className={"form-control " + styles.inputForm2}
                onKeyUp={(e: any) => {
                  setPrice(e.target.value);
                }}
                placeholder={
                  "Harga iklan tulis disini (memudahkan proses rekapitulasi)"
                }
                maxLength={255}
                onKeyPress={(e) => numeric(e)}
              />
            </div>
          </div>
        </div>
        <div className={"mt-2"}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-right">
              Tanggal Mulai
            </label>
            <div className="col-sm-9">
              <DatePicker
                className={"form-control " + styles.inputForm2}
                selected={startDate}
                value={startDate}
                onChange={(e: any) => {
                  setStartDate(e);
                }}
                type="text"
                dateFormat="dd MMM yyyy"
              />
            </div>
          </div>
        </div>
        <div className={"mt-2"}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-right">
              Tanggal Selesai
            </label>
            <div className="col-sm-9">
              <DatePicker
                className={"form-control " + styles.inputForm2}
                selected={endDate}
                value={endDate}
                onChange={(e: any) => {
                  setEndDate(e);
                }}
                type="text"
                dateFormat="dd MMM yyyy"
              />
            </div>
          </div>
        </div>
        <div className={"mt-2"}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-right">
              Nama Marketing
            </label>
            <div className="col-sm-9">
              <TextareaAutosize
                className={"form-control " + styles.inputForm2}
                onKeyUp={(e: any) => {
                  setMarketing(e.target.value);
                }}
                placeholder={"Tuliskan nama marketing"}
                maxLength={255}
              />
            </div>
          </div>
        </div>
        <div className={"mt-2"}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-right">Gambar</label>
            <div className="col-sm-9">
              {img && (
                <div style={{ position: "relative" }}>
                  <img src={img} className={styles.reviewImg} alt="img" />
                  <button
                    className={"btn btn-danger " + styles.btnDeleteImg}
                    onClick={() => setImg("")}
                  >
                    Hapus Gambar
                  </button>
                </div>
              )}
              <input id="file" type="file" onChange={(e) => handleGetImg(e)} />
              <label htmlFor="file">Sisipkan Gambar</label>
            </div>
          </div>
        </div>
        <div className={"mt-2"}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-right">Status</label>
            <div className="col-sm-9">
              <span className={"mx-3"}>
                <input
                  className={styles.radio}
                  type="radio"
                  id="is_active"
                  value={1}
                  checked={isActive === 1}
                  onChange={() => {
                    setIsActive(1);
                  }}
                />
                <label htmlFor="is_active">Aktif</label>
              </span>
              <span className={"mx-3"}>
                <input
                  className={styles.radio}
                  type="radio"
                  id="is_active_n"
                  value={0}
                  checked={isActive === 0}
                  onChange={() => {
                    setIsActive(0);
                  }}
                />
                <label htmlFor="is_active_n">Tidak Aktif</label>
              </span>
            </div>
          </div>
        </div>
        <div className={"mt-2"}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-right"></label>
            <div className="col-sm-9">
              <button
                type="button"
                className={`btn ${styles.btnCancel}`}
                onClick={() => history.goBack()}
              >
                Back
              </button>
              <button
                type="button"
                className={"btn btn-primary " + styles.btnSave}
                onClick={handleSave}
              >
                {isLoading ? "Loading..." : "Simpan Iklan"}
              </button>

              <SweetAlert
                show={isSuccessSave}
                title="Sukses"
                text="Iklan berhasil dibuat."
                onConfirm={() => {
                  setIsSuccessSave(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormCreateAds;
