import React from "react";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

import styles from "../ads.module.css";

export const TableListAds = ({ ads }: any) => {
  return (
    <div className="table-responsive">
      <table className={`table table-striped ${styles.table}`}>
        <thead className="thead-light">
          <tr>
            <th>Title</th>
            <th>Adv</th>
            <th>URL</th>
            <th>Mulai</th>
            <th>Selesai</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {ads?.map((data_ads: any, i: number) => (
            <tr key={i}>
              <td style={{ width: "25%" }}>{data_ads.title}</td>
              <td>{data_ads?.category?.name}</td>
              <td>{data_ads.url}</td>
              <td className="text-center">{data_ads.start_date}</td>
              <td className="text-center">{data_ads.end_date}</td>
              <td style={{ width: "100px" }} className="text-center">
                {data_ads.is_active === "1" ? (
                  <Badge bg="success">Aktif</Badge>
                ) : (
                  <Badge bg="danger">Tidak Aktif</Badge>
                )}
              </td>
              <td style={{ width: "100px" }} className="text-center">
                <Link
                  to={`/admin/ads/edit/${data_ads.id}`}
                  className={styles.linkTable}
                >
                  Edit
                </Link>
                <Link
                  to={`/admin/ads/show/${data_ads.id}`}
                  className={styles.linkTable}
                >
                  Detail
                </Link>
              </td>
            </tr>
          ))}
          {ads?.length === 0 && (
            <tr>
              <td colSpan={6}>Tidak ditemukan data!</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
