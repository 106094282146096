import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
// @ts-ignore
import Select from "react-select";

import Header from "@components/header/desktop/Header";
import { api } from "@shared/api";
import { TableListAds } from "@components/ads/tables";
import { useAuth } from "@hooks/use-auth";
import { useGetAdsQuery } from "@store/service/apis";

const Ads = () => {
  // const [ads, setAds] = useState<any>();
  const [optionAdsType, setOptionAdsType] = useState<any>();
  const [formSearch, setFormSearch] = useState<any>();
  const [searchQuery, setSearchQuery] = useState<any>();

  const { user } = useAuth();

  useEffect(() => {
    window.document.title = "Iklan | PusaranMedia.com";
    api
      .get(`admin/ads-type/for-form`)
      .then((res) => setOptionAdsType(res.data.data));
    // api.get(`admin/ads`).then((res) => setAds(res.data.data));
  }, []);

  const handleFilter = () => {
    setSearchQuery(formSearch);
  };

  const { data: ads } = useGetAdsQuery({ ...searchQuery });

  return (
    <>
      <Header user={user} />
      <Container className="mt-5 pt-2">
        <Row>
          <Col xs md={12}>
            <Link to="/admin/ads/create" className={"btn btn-primary my-3"}>
              Tambah Iklan
            </Link>

            <div className="wrap mb-2">
              <div className="form-group row">
                <Col sm={5}>
                  <Select
                    value={optionAdsType?.find(
                      (el: any) => el.id === formSearch?.adsType
                    )}
                    placeholder="Pilih jenis iklan"
                    onChange={(e: any) =>
                      setFormSearch({ ...formSearch, ads_type_id: e?.id })
                    }
                    options={optionAdsType}
                  />
                </Col>
                <Col sm={5}>
                  <TextareaAutosize
                    className={"form-control"}
                    onKeyUp={(e: any) =>
                      setFormSearch({ ...formSearch, title: e.target.value })
                    }
                    placeholder={"Cari iklan"}
                    maxLength={255}
                  />
                </Col>
                <Col sm={1} style={{ display: "flex", alignItems: "center" }}>
                  <div key={`active`}>
                    <Form.Check
                      style={{ margin: 0 }}
                      type={"checkbox"}
                      id={`active`}
                      label={`Aktif`}
                      onClick={(e: any) =>
                        setFormSearch({
                          ...formSearch,
                          is_active: e.target.checked ? 1 : 0,
                        })
                      }
                    />
                  </div>
                </Col>
                <Col sm={1}>
                  <Button variant="outline-success" onClick={handleFilter}>
                    Filter
                  </Button>
                </Col>
              </div>
            </div>

            <TableListAds ads={ads} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Ads;
