import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./service/apis";
import authReducer from "./entities/auth";

export const configureAppStore = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: { warnAfter: 128 },
    }).concat(apiSlice.middleware),
  devTools: process.env.REACT_APP_ENV ? false : true,
});

export type RootState = ReturnType<typeof configureAppStore.getState>;
export type AppDispatch = typeof configureAppStore.dispatch;
