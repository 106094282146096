import React from 'react'

const Gallery = () => {
  return (
    <div>
      galeri
    </div>
  )
}

export default Gallery
