import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import MainRouter from "@pages/router/main-router";
import { configureAppStore } from "./store/index";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

const store = configureAppStore;

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <MainRouter />
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);
