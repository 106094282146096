import React, { FC } from "react";

import { IFieldProps } from "@shared/interfaces";
import { cn } from "@shared/functions";

export const RadioField: FC<IFieldProps> = ({
  formik,
  name,
  options,
  inline,
}) => {
  return (
    <div
      className={cn(
        "tw-flex",
        inline ? "tw-flex-row tw-gap-4" : "tw-flex-col tw-gap-2"
      )}
    >
      {options?.map((option, index) => (
        <div key={index}>
          <input
            type="radio"
            name={name}
            id={`${name}-${option.value}`}
            value={option.value}
            checked={formik.values[name] === option.value}
            onChange={formik.handleChange}
          />
          <label htmlFor={`${name}-${option.value}`}>{option.label}</label>
        </div>
      ))}
    </div>
  );
};
