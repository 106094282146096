import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import Detail from "@components/ads/card/Detail";
import Header from "@components/header/desktop/Header";
import { useGetAdDetailQuery } from "@store/service/apis";
import { useAuth } from "@hooks/use-auth";

const ShowAds = ({ match }: any) => {
  const [adsID, setAdsID] = useState<any>();

  const { user } = useAuth();

  const { data: ads } = useGetAdDetailQuery(adsID, { skip: !adsID });

  useEffect(() => {
    window.document.title = "Detail Iklan | PusaranMedia.com";
  }, []);

  useEffect(() => {
    if (match.params.id) {
      setAdsID(match.params.id);
    }
  }, [match]);

  return (
    <>
      <Header user={user} />
      <Container className="mt-5 pt-3">
        <Row>
          <Col xs md={12}>
            <Link to="/admin/ads" className={"btn btn-outline-secondary my-3"}>
              Kembali ke Iklan
            </Link>
            <div className="table-responsive">
              <Detail ads={ads} />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ShowAds;
