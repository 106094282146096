import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
// @ts-ignore
import renderHTML from "react-render-html";
import { Link } from "react-router-dom";

import styles from "./show.module.css";
import Header from "@components/header/desktop/Header";
import Popular from "@components/news/desktop/popular/Popular";
import { useAuth } from "@hooks/use-auth";
import { useGetNewsDetailQuery } from "@store/service/apis";

const ShowArticle = ({ match }: any) => {
  const [newsID, setNewsID] = useState<number>();

  const { user } = useAuth();

  useEffect(() => {
    setNewsID(match.params.id);
  }, [match]);

  const { data: news } = useGetNewsDetailQuery(newsID, { skip: !newsID });

  return (
    <>
      <Header user={user} />
      <Container className="mt-5 pt-3">
        <Row>
          <Col xs md={8}>
            {news && (
              <div className={styles.my20}>
                <button className="btn btn-primary btn-sm me-2">
                  <Link to="/admin/profile" className="text-white">
                    Kembali
                  </Link>
                </button>
                <button className="btn btn-warning btn-sm">
                  <Link
                    to={`/admin/news/edit/${news.id}`}
                    className="text-white"
                  >
                    Ubah
                  </Link>
                </button>
                <h1 className={styles.title + " mt-3"}>{news.title}</h1>
                {news.img_lg && (
                  <img src={news.img_lg} alt="img" className={styles.imgNews} />
                )}
                {news.img_title && (
                  <div className={styles.imgTitle}>{news.img_title}</div>
                )}
                {news.teaser && (
                  <div className={styles.teaser}>{news.teaser}</div>
                )}
                {news.content && (
                  <div className={"mt-3"}>{renderHTML(news.content)}</div>
                )}
              </div>
            )}
          </Col>
          <Col xs md={4}>
            <div className="sticky-top">
              <Popular />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ShowArticle;
