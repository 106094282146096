import { IFieldOptions } from "@shared/interfaces";
import { apiSlice } from ".";

export const categoryApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => `news-category`,
      transformResponse: ({ data }) => data,
      providesTags: [{ type: "category", id: "LIST" }],
    }),
    getCategoryDetail: builder.query({
      query: (id) => `news-category/${id}`,
      transformResponse: ({ data }) => data,
      providesTags: [{ type: "category", id: "DETAIL" }],
    }),
    getCategoryOptions: builder.query<IFieldOptions[], any>({
      query: () => `news-category/select2`,
      transformResponse: ({ data }) => data,
      providesTags: [{ type: "category", id: "OPTIONS" }],
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useLazyGetCategoriesQuery,
  useGetCategoryDetailQuery,
  useLazyGetCategoryDetailQuery,
  useGetCategoryOptionsQuery,
  useLazyGetCategoryOptionsQuery,
} = categoryApi;
