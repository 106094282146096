import axios from "axios";

const url = process.env.REACT_APP_BASEURL;

const tokenLocal = localStorage.getItem("c_user");
const token = tokenLocal ? JSON.parse(atob(tokenLocal)).access_token : "";

export const api = axios.create({
  baseURL: url,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});
