import React from "react";
import { Link } from "react-router-dom";

import styles from "./Popular.module.css";
import { useGetNewsQuery } from "@store/service/apis";

const Popular = () => {
  const { data: popNews } = useGetNewsQuery({ limit: 5, order_by: "hits" });

  return (
    <>
      <h3 className={styles.sectionTitle}>
        <span>Berita Terpopuler</span>
      </h3>
      <ul className={styles.trendingList}>
        {popNews &&
          popNews?.map((news, i) => {
            return (
              <li key={i} className="trending-list__li">
                <Link to={`/admin/news/${news.id}`}>{news.title}</Link>
              </li>
            );
          })}
      </ul>
    </>
  );
};

export default Popular;
