import React from "react";

import Login from "@pages/login/Login";
import Profile from "@pages/profile/profile";
import { useAuth } from "@hooks/use-auth";

const Home = () => {
  const { user } = useAuth();

  return <>{user ? <Profile /> : <Login />}</>;
};

export default Home;
