import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import Header from "@components/header/desktop/Header";
import EditAdsComponent from "@components/ads/edit/edit-ads";
import { useAuth } from "@hooks/use-auth";
import { useGetAdDetailQuery } from "@store/service/apis";

const EditAds = ({ match }: any) => {
  const [adsID, setAdsID] = useState<any>();

  const { user } = useAuth();

  const { data: ads } = useGetAdDetailQuery(adsID, { skip: !adsID });

  useEffect(() => {
    window.document.title = "Detail Iklan | PusaranMedia.com";
  }, []);

  useEffect(() => {
    if (match.params.id) {
      setAdsID(match.params.id);
    }
  }, [match]);

  return (
    <>
      <Header user={user} />
      <Container className="mt-5 pt-3">
        <Row>
          {ads && (
            <Col xs md={12}>
              <Link
                to={`/admin/ads/${ads.id}`}
                className={"btn btn-outline-secondary my-3"}
              >
                Kembali ke Detail Iklan
              </Link>
              <div className="table-responsive">
                <EditAdsComponent ads={ads} />
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default EditAds;
