import React, { FC } from "react";

import { cn } from "@shared/functions";
import { IButtonSubmitProps } from "@shared/interfaces";

export const ButtonSubmit: FC<IButtonSubmitProps> = ({ formik, label }) => {
  const isDisabled =
    // formik.isSubmitting ||
    Object.keys(formik.errors).length > 0 || !formik.dirty;

  return (
    <button
      type="submit"
      disabled={isDisabled}
      className={cn(
        "btn btn-primary !tw-bg-[#ff7000] tw-py-4 tw-px-8 tw-text-base tw-border-none disabled:tw-cursor-not-allowed disabled:tw-opacity-50"
      )}
    >
      {formik.isSubmitting ? "Loading..." : label ?? "Simpan Artikel"}
    </button>
  );
};
