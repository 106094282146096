import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import imageCompression from "browser-image-compression";
import { useFormik } from "formik";
import size from "lodash/size";

import styles from "./edit-news.module.css";
import Header from "@components/header/desktop/Header";
import { useAuth } from "@hooks/use-auth";
import {
  useGetCategoryOptionsQuery,
  useGetNewsDetailQuery,
  useUpdateNewsMutation,
} from "@store/service/apis";
import { cn } from "@shared/functions";
import {
  ButtonSubmit,
  RadioField,
  SelectField,
  TextField,
  TinyMceField,
} from "@components/fields";
import { IFieldOptions } from "@shared/interfaces";

const EditArticle = (props: any) => {
  const [newsID, setNewsID] = useState<any>();

  useEffect(() => {
    setNewsID(props.match.params.id);
  }, [props]);

  const { user } = useAuth();

  const { data: news } = useGetNewsDetailQuery(newsID, { skip: !newsID });

  const { data: optionCategories } = useGetCategoryOptionsQuery({});

  const handleGetImg = async (e: any) => {
    const imageFile = e.target.files[0];
    const options = {
      maxSizeMB: 0.7,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      getBase64(compressedFile);
    } catch (error) {
      console.log(error);
    }
  };

  const getBase64 = (file: any) => {
    let reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      formik.setFieldValue("mainImage", reader.result);
    };
    reader.onerror = function (error: any) {
      console.log("Error: ", error);
    };
  };

  const [updateNews] = useUpdateNewsMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: news?.title || "",
      teaser: news?.teaser || "",
      mainImage: "",
      imgTitle: news?.img_title || "",
      content: news?.content || "",
      category: news?.category_id
        ? optionCategories?.find((item) => +item.value === news?.category_id)
        : ({} as IFieldOptions),
      isHeadline: news?.is_headline || "0",
      isActive: news?.is_active || "0",
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.title) {
        errors.title = "Judul tidak boleh kosong!";
      }
      if (size(values.category) === 0) {
        errors.category = "Kategori berita jangan dikosongkan!";
      }
      if (!values.content) {
        errors.content = "Konten berita tidak boleh kosong!";
      }
      return errors;
    },
    validateOnMount: true,
    onSubmit: (values) => {
      const payload = {
        id: newsID,
        title: values.title,
        teaser: values.teaser,
        content: values.content,
        category_id: values.category?.value,
        photo: values.mainImage,
        img_title: values.imgTitle,
        is_active: values.isActive,
        is_headline: values.isHeadline,
      };
      updateNews(payload)
        .unwrap()
        .then(() => {
          props.history.push("/admin/profile");
        });
    },
  });

  return (
    <>
      <Header user={user} />
      <Container className="mt-5 pt-3">
        <Row className={"justify-content-center"}>
          <Col xs md={12}>
            <form
              onSubmit={formik.handleSubmit}
              className={cn(styles.wrapForm, "tw-space-y-6")}
            >
              <RadioField
                formik={formik}
                name="isHeadline"
                label="Headline"
                inline
                options={[
                  { value: "1", label: "Headline" },
                  { value: "0", label: "Bukan Headline" },
                ]}
              />
              <SelectField
                formik={formik}
                name="category"
                options={optionCategories}
                placeholder="Pilih kategori berita"
              />
              <TextField
                formik={formik}
                name="title"
                label="Judul"
                placeholder="Tulis judul kamu disini!"
              />
              <TextField
                formik={formik}
                name="teaser"
                placeholder="Tulis cuplikan kamu di sini"
              />

              <div className="tw-flex tw-flex-col">
                {formik.values?.mainImage && (
                  <div className="tw-relative tw-max-w-sm">
                    <img
                      src={formik.values?.mainImage}
                      className={styles.reviewImg}
                      alt="img"
                    />
                    <button
                      className={
                        "btn btn-danger tw-absolute tw-top-2 tw-right-2"
                      }
                      onClick={() => formik.setFieldValue("mainImage", "")}
                    >
                      Hapus Gambar
                    </button>
                  </div>
                )}
                <input
                  id="file"
                  type="file"
                  onChange={(e) => handleGetImg(e)}
                />
                <label htmlFor="file">Sisipkan Gambar</label>
              </div>
              <TextField
                formik={formik}
                name="imgTitle"
                placeholder="Keterangan gambar"
              />
              <div>
                <TinyMceField formik={formik} name="content" />
                {formik.errors.content && (
                  <p className="msg-error">{formik.errors.content}</p>
                )}
              </div>
              {user && (
                <RadioField
                  formik={formik}
                  name="isActive"
                  label="Status"
                  options={[
                    { value: "1", label: "Tayang" },
                    { value: "0", label: "Tidak Tayang" },
                  ]}
                  inline
                />
              )}
              <ButtonSubmit formik={formik} label="Simpan Artikel" />
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditArticle;
