import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Gallery from "@pages/gallery/Gallery";
import Login from "@pages/login/Login";
import CreateArticle from "@pages/news/create/create-news.page";
import EditArticle from "@pages/news/edit/edit-news.page";
import ShowArticle from "@pages/news/show/show";
import Ads from "@pages/ads/ads.page";
import ShowAds from "@pages/ads/show-ads.page";
import EditAds from "@pages/ads/edit-ads.page";
import CreateAds from "@pages/ads/create-ads.page";
import ProtectedRouter from "./protected-router";
import Home from "@pages/home/home";
import Profile from "@pages/profile/profile";
import CategoryPage from "@pages/category/Category.page";

const MainRouter = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/login" component={Login} />
        <ProtectedRouter path="/admin/profile" component={Profile} />
        <ProtectedRouter
          path="/admin/create-article"
          component={CreateArticle}
        />
        <ProtectedRouter path="/admin/news/edit/:id" component={EditArticle} />
        <ProtectedRouter path="/admin/news/:id" component={ShowArticle} />
        <ProtectedRouter path="/admin/img-gallery" component={Gallery} />
        <ProtectedRouter path="/admin/ads/show/:id" component={ShowAds} />
        <ProtectedRouter path="/admin/ads/edit/:id" component={EditAds} />
        <ProtectedRouter path="/admin/ads/create" component={CreateAds} />
        <ProtectedRouter path="/admin/ads" component={Ads} />
        <ProtectedRouter path="/admin/category" component={CategoryPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default MainRouter;
