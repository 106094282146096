import { useSelector } from "react-redux";

import { getAccessToken, getMyData } from "@store/entities/auth";

export const useAuth = () => {
  const { mydata } = useSelector(getMyData);
  const { access_token } = useSelector(getAccessToken);

  return { user: mydata, access_token };
};
