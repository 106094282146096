import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const numeric = (e: any) => {
  let ch = String.fromCharCode(e.which);
  if (!/[0-9]/.test(ch)) {
    e.preventDefault();
  }
};

export const getBase64 = (file: any) => {
  let res;
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => (res = reader.result);
  reader.onerror = (error) => {
    console.log("Error: ", error);
  };
  return res;
};

export const assemblyParameters = (params: any) => {
  if (isUndefined(params) || isEmpty(params)) return null;
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
};

export const removeEmptyObject = (e: any) =>
  Object.entries(e).reduce((a: any, [k, v]) => (!v ? a : ((a[k] = v), a)), {});
