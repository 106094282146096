import React, { FC, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

import { cn } from "@shared/functions";
import { IFieldProps } from "@shared/interfaces";

export const TinyMceField: FC<IFieldProps> = ({
  formik,
  name,
  height,
  className,
  inline,
  label,
  maxWidth,
  quality,
  noLabel,
}) => {
  const editorRef = useRef<any>(null);

  // const [uploadFile] = useUploadFileMutation();

  // const handleUploadImage: any = (
  //   blobInfo: any,
  //   success: any,
  //   failure: any
  // ) => {
  //   const url = `file-upload?width=${maxWidth || 780}&quality=${quality || 65}`;
  //   const formData = new FormData();
  //   formData.append("file", blobInfo.blob(), blobInfo.filename());
  //   const reader = new FileReader();
  //   reader.readAsDataURL(blobInfo.blob());
  //   reader.onloadend = function () {
  //     const body = { url, file: reader.result };
  //     uploadFile(body)
  //       .unwrap()
  //       .then((res) => success(res))
  //       .catch((error) => failure(error.message));
  //   };
  // };

  return (
    <div
      className={cn(
        "w-full flex flex-col gap-2",
        className,
        inline ? "flex-row items-center" : "flex-col"
      )}
    >
      <Editor
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        licenseKey="gpl"
        onInit={(_evt, editor) => (editorRef.current = editor)}
        onEditorChange={(content, _editor) => {
          formik.setFieldValue(name, content);
        }}
        value={formik.values[name]}
        init={{
          branding: false,
          statusbar: false,
          height: height || 300,
          menubar: true,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code wordcount",
          ],
          toolbar: `undo redo | fontsizeselect | image |
            bold italic backcolor | alignleft aligncenter 
            alignright alignjustify | bullist numlist outdent indent | 
            removeformat`,
          document_base_url: "/tinymce", // Set base URL for all relative URLs
          skin_url: "/tinymce/skins/ui/oxide", // Ensure correct skin URL
          content_css: "/tinymce/skins/content/default/content.css", // Ensure correct content CSS URL
          theme_url: "/tinymce/themes/silver/theme.min.js", // Ensure correct theme URL
          model_url: "/tinymce/models/dom/model.js", // Ensure correct model URL
          icons_url: "/tinymce/icons/default/icons.js", // Ensure correct icons URL
          external_plugins: {
            link: "/tinymce/plugins/link/plugin.min.js",
            table: "/tinymce/plugins/table/plugin.min.js",
            lists: "/tinymce/plugins/lists/plugin.min.js",
            code: "/tinymce/plugins/code/plugin.min.js",
            image: "/tinymce/plugins/image/plugin.min.js",
          },
          mobile: { menubar: true },
          // images_upload_handler: handleUploadImage,
        }}
      />
    </div>
  );
};
