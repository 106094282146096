import { IFieldProps } from "@shared/interfaces";
import React, { FC } from "react";
// @ts-ignore
import Select from "react-select";

export const SelectField: FC<IFieldProps> = ({
  formik,
  name,
  options,
  placeholder,
}) => {
  return (
    <div>
      <Select
        value={formik.values[name]}
        placeholder={placeholder}
        onChange={(e: any) => {
          formik.setFieldValue(name, e);
        }}
        options={options}
      />
      {formik.errors[name] && (
        <p className="msg-error">{formik.errors[name]}</p>
      )}
    </div>
  );
};
