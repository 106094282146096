/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { decode, encode } from "js-base64";
import { RootState } from "..";
import { User } from "store/service/models";
import { authApi } from "@store/service/apis";

const clearLocalStorage = () => {
  localStorage.removeItem("PM-SC");
  localStorage.removeItem("PM-CU");
  localStorage.removeItem("PM-T");
};

const tokenLocal = localStorage.getItem("PM-T");
const userLocal = localStorage.getItem("PM-CU");

const userFromLocalstorage =
  userLocal && (JSON.parse(decode(userLocal)) as User);

const slice = createSlice({
  name: "auth",
  initialState: {
    access_token: tokenLocal && (decode(tokenLocal) as string),
    data: userFromLocalstorage as User,
  },
  reducers: {
    fetchLogout: (state) => {
      clearLocalStorage();
      state.access_token = "";
      state.data = {} as User;
    },
    setAuthData: (state, { payload }: PayloadAction<any>) => {
      localStorage.setItem("PM-CU", encode(JSON.stringify(payload)));
      state = { ...state, data: payload };
    },
    setCredentials: (state, { payload }: PayloadAction<any>) => {
      const { access_token, data } = payload;
      localStorage.setItem("PM-T", encode(access_token));
      localStorage.setItem("PM-CU", encode(JSON.stringify(data)));
      state.access_token = access_token;
      state.data = data;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.access_token = payload.access_token;
        state.data = payload.data;
      }
    );
  },
});

export const { fetchLogout, setAuthData, setCredentials } = slice.actions;

export default slice.reducer;

export const getMyAuth = createSelector(
  (state: RootState) => ({ auth: state.auth }),
  (state) => state
);

export const getMyData = createSelector(
  (state: RootState) => ({ mydata: state.auth.data }),
  (state) => state
);

export const getAccessToken = createSelector(
  (state: RootState) => ({ access_token: state.auth.access_token }),
  (state) => state
);

export const getAuth = createSelector(
  (state: RootState) => ({ auth: state.auth }),
  (state) => state
);
