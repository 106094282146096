import React, { useEffect } from "react";
import FormCreateAds from "./form/create.form";

const CreateAdsComponent = () => {
  useEffect(() => {
    window.document.title = "Tambah Iklan | PusaranMedia.com";
  }, []);

  return <FormCreateAds />;
};

export default CreateAdsComponent;
