import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

import "./style.css";

const TableListNews = (props) => {
  const [dataNews, setDataNews] = useState([]);
  // const [user, setUser] = useState(null);

  useEffect(() => {
    if (props !== undefined) {
      setDataNews(props.news);
      // setUser(props.user);
    }
  }, [props]);

  const columns = [
    {
      name: "Judul",
      selector: "title",
      sortable: true,
      width: "55%",
    },
    {
      name: "Kategori",
      selector: "category.name",
      sortable: true,
      width: "50",
    },
    {
      name: "Terbit",
      cell: (row) => (
        <>
          {row.is_active === "1" ? (
            <span className="tw-bg-blue-500 tw-text-white tw-rounded-sm tw-py-1 tw-px-4">
              tampil
            </span>
          ) : (
            <span className="tw-bg-red-500 tw-text-white tw-rounded-sm tw-py-1 tw-px-4">
              tidak
            </span>
          )}
        </>
      ),
      width: "50",
    },
    {
      name: "Aksi",
      right: true,
      cell: (row) => (
        <>
          {/* {row.writer?.id === user.user?.id ||
          user.user.account_type === 1 ||
          user.user.account_type === 2 ? (
          ) : null} */}
          <Link className={"linkEdit"} to={`/admin/news/edit/${row.id}`}>
            Edit
          </Link>
          <Link className={"linkDetail"} to={`/admin/news/${row.id}`}>
            Detail
          </Link>
        </>
      ),
      width: "15%",
    },
  ];

  return (
    <div>
      <DataTable
        noHeader={true}
        columns={columns}
        data={dataNews}
        theme="solarized"
        pagination={false}
      />
    </div>
  );
};

export default TableListNews;
