import React, { useEffect } from "react";
import { useParams } from "react-router";

import EditAdsForm from "./form/edit.form";

const EditAdsComponent = (props: any) => {
  const params: any = useParams();
  const id = params?.id;

  useEffect(() => {
    window.document.title = "Update Iklan | PusaranMedia.com";
  }, []);

  return <EditAdsForm ads={props?.ads} adsID={id} />;
};

export default EditAdsComponent;
