import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import auth from "@shared/auth/auth";
import Header from "@components/header/desktop/Header";

const CategoryPage = () => {
  const [user, setUser] = useState<any>();

  useEffect(() => {
    window.document.title = "Iklan | PusaranMedia.com";
    const authUser = auth.user() || "";
    if (authUser) {
      setUser(JSON.parse(authUser));
    }
  }, []);

  // const formik = useFormik({
  //   initialValues: {
  //     cover: "",
  //   },
  //   onSubmit: (values) => {
  //     console.log(values);
  //   },
  // });

  return (
    <>
      <Header user={user} />
      <Container className="mt-5 pt-2">
        <Row>
          <Col xs md={12}>
            <Link to="/admin/category/form" className={"btn btn-primary my-3"}>
              Tambah Kanal
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CategoryPage;
