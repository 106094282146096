import { assemblyParameters } from "@shared/functions";
import { apiSlice } from ".";
import { News } from "../models";

export const newsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNews: builder.query<News[], any>({
      query: (params) => `news?${assemblyParameters(params)}`,
      transformResponse: ({ data }) => data,
      providesTags: [{ type: "news", id: "LIST" }],
    }),
    getNewsDetail: builder.query<News, any>({
      query: (id) => `news/${id}`,
      transformResponse: ({ data }) => data,
      providesTags: [{ type: "news", id: "DETAIL" }],
    }),
    createNews: builder.mutation({
      query: (data) => ({
        url: `news`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "news", id: "LIST" }],
    }),
    updateNews: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `news/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: [
        { type: "news", id: "LIST" },
        { type: "news", id: "DETAIL" },
      ],
    }),
  }),
});

export const {
  useGetNewsQuery,
  useLazyGetNewsQuery,
  useGetNewsDetailQuery,
  useLazyGetNewsDetailQuery,
  useCreateNewsMutation,
  useUpdateNewsMutation,
} = newsApi;
